import { humanize } from '@mr-yum/frontend-core/dist/support/strings'
import {
  FieldMessage,
  FormControl,
  Select,
  SelectOption,
  SelectProps,
} from '@mr-yum/frontend-ui'
import React, { PropsWithChildren, useCallback } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { HookFormControlProps } from './shared'

export type SelectFieldProps = SelectProps<SelectOption> & HookFormControlProps

export const SelectField = ({
  name,
  label,
  disabled,
  caption,
  options,
  ...props
}: PropsWithChildren<SelectFieldProps>) => {
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control })

  const getSelectValue = useCallback(
    (
      fieldValue: string | number,
      options: SelectOption[],
    ): SelectOption | null => {
      if (!fieldValue) return null

      // value exists in options, render the option itself
      const existingOption = options.find(
        (option) => option && option.id === fieldValue,
      )

      if (existingOption) {
        return existingOption
      }

      return {
        id: fieldValue,
        label: humanize(String(fieldValue)),
      }
    },
    [],
  )

  const value = getSelectValue(field.value, options)

  return (
    <FormControl
      label={label}
      disabled={disabled}
      caption={caption}
      feedback={
        !!fieldState.error && (
          <FieldMessage type="critical">
            {fieldState.error.message}
          </FieldMessage>
        )
      }
      htmlFor={name}
    >
      <Select
        {...field}
        {...props}
        options={options}
        value={value}
        error={!!fieldState.error}
        onChange={({ selectedItem }) => {
          field.onBlur()
          field.onChange(selectedItem ? selectedItem.id : null)
        }}
      />
    </FormControl>
  )
}
